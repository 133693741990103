import styled from "@emotion/styled";
import { Container } from "react-bootstrap";
import BackgroundImage from "../../assets/img/challenges-background.jpeg";

export const Section = styled.section`
  height: 100vh;
  width: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  position: relative;
  overflow-y: scroll;
  overflow-x: none;
`;

export const InnerContainer = styled(Container)``;

export const ContentWrapper = styled.div`
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Content = styled.div`
  max-width: 750px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Title = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 34px;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

export const Separator = styled.div`
  position: relative;
  height: 4px;
  width: 60px;
  background-color: #fff;
  margin: 0 auto;
`;

export const Description = styled.p`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  max-width: 750px;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 16px;
  line-height: 1.75em;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.55em;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  max-width: 750px;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 16px;
  line-height: 1.75em;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.55em;
  }
`;

export const Bold = styled.span`
  font-weight: 600;
`;
