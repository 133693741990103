import { Interweave } from "interweave";
import { useSelector } from "react-redux";
import {
  Bold,
  Content,
  ContentWrapper,
  Description,
  Fader,
  InnerContainer,
  Section,
  Separator,
  Text,
  Title,
} from "./styled";

const Solutions = () => {
  const language = useSelector((state) => state.config.language);

  const renderTitle = () => {
    if (language === "no") {
      return "Løsninger";
    }
    return "Solutions";
  };

  const renderDescription = () => {
    if (language === "no") {
      return "Vi leverer forskningsbaserte psykologiske løsninger til firmaer, offentlige virksomheter og folk som vil forbedre sitt klimaavtrykk og ivareta naturen. Vi tilbyr foredrag, kurs, møter, opplæring og analyser.";
    }
    return "Why do people deny the climate crisis? Why do people choose differently than planned? How can nudges change choices? How can connecting to nature improve climate choices?";
  };

  const renderText = () => {
    if (language === "no") {
      return (
        <Interweave
          content="
          <Bold>Hva slags innsikt trengs for å endre eget klimaavtrykk?</Bold>
          <br />
          Forstå hva som må endres. Forstå hvordan endring er vanskelig.
          Effektive virkemidler for forandring.
          <br />
          <br />
          <Bold>Når tar egentlig follk valg som påvirker naturen, bærekraft og klima?</Bold>
          <br />
          Hvordan utforme beslutningsarkitektur som fremmer bedre valg.
          <br />
          <br />
          <Bold>Hvordan er forbindelse med naturen nødvendig for mening i livetog på jobb?</Bold>
          <br />
          Hvordan kan sterkere forbindelse til naturen fremme bedre
          klimavalg? Prosesser for dypere forståelse, personlig vekt og
          utvikling.
          <br />
          <br />
          <Bold>Hvordan motivere til positiv klimaendring?</Bold>
          <br />
          Forstå motstand mot endring og utforme verktøy som sikrer
          forpliktelse til bærekraftige valg."
        />
      );
    }
    return (
      <Interweave
        content="
        <Bold>What insights do we need to change the climate footprint?</Bold>
        <br />
        Understand what needs to be changed. Understand why change is difficult.
        <br />
        <br />
        <Bold>When do people actually make decisions that influence nature and the climate?</Bold>
        <br />
        How to design decision architectures that promote climate friendly choices.
        <br />
        <br />
        <Bold>Why is connection to nature necessary for meaning in life and at work?</Bold>
        <br />
        How can connecting to nature improve climate choices and enable more sustainable lives? Design journeys for deeper learning and development.
        <br />
        <br />
        <Bold>How to create motivation for climate change?</Bold>
        <br />
        Understand resistance to change and design tools that ensure commitment for sustainable change.
    "
      />
    );
  };

  return (
    <Section>
      <InnerContainer>
        <ContentWrapper>
          <Content>
            <Title>{renderTitle()}</Title>
            <Separator />
            <Description>{renderDescription()}</Description>
            <Text>{renderText()}</Text>
          </Content>
        </ContentWrapper>
      </InnerContainer>
    </Section>
  );
};

export default Solutions;
