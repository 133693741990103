import {
  InnerNavigationContainer,
  NavigationContainer,
  Logo,
  SelectionContainer,
  SelectButton,
} from "./styled";
import navigationLogo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/actions/config";

const Index = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.config.language);

  const renderLanguage = () => {
    if (language === "no") {
      return "Norsk";
    }
    return "English";
  };

  const changeLanguage = () => {
    if (language === "no") {
      dispatch(setLanguage("en"));
    } else {
      dispatch(setLanguage("no"));
    }
  };

  return (
    <NavigationContainer>
      <InnerNavigationContainer>
        <Logo src={navigationLogo} />
        <SelectionContainer>
          <SelectButton type="button" onClick={changeLanguage}>
            {renderLanguage()}
          </SelectButton>
        </SelectionContainer>
      </InnerNavigationContainer>
    </NavigationContainer>
  );
};

export default Index;
