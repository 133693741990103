import * as type from "../types/config";

const initialState = {
  language: "no",
};

export const config = (state = initialState, payload) => {
  switch (payload.type) {
    case type.SET_LANGUAGE:
      return {
        ...state,
        language: payload.selection,
      };
    default:
      return state;
  }
};
