import { Interweave } from "interweave";
import { useSelector } from "react-redux";
import {
  Content,
  Description,
  InnerContainer,
  Text,
  Section,
  Separator,
  Title,
  ContentWrapper,
} from "./styled";

const Welcome = () => {
  const language = useSelector((state) => state.config.language);

  const renderTitle = () => {
    if (language === "no") {
      return "Velkommen";
    }
    return "Welcome";
  };

  const renderDescription = () => {
    if (language === "no") {
      return "Tre psykologer med doktorgrad i psykologi, økonomi og økonomisk psykologi. En leder, veileder og kunstner med doktorgrad i innovasjon og entreprenørskap. Akademisk erfaring fra universiteter og høyskoler. Praktisk erfaring fra virksomheter og offentlige institusjoner.";
    }
    return "Three psychologists with PhDs in psychology, economy and economic psychology. One leader, coach and artist with PhD in innovation and entrepreneurship. Academic experience from Universities and Business Schools. Real life experience from business corporations and public institutions.";
  };

  const renderText = () => {
    if (language === "no") {
      return (
        <Interweave
          content="
          Institutt for Klimapsykologi. Klimapsykologene.
          <br />
          <br />
          Klimavennlige valg. Bærekraftige beslutninger. Endringer med
          positive klimaeffekter. Folk, firmaer og virksomheter.
          <br />
          <br />
          Tre psykologer med doktorgrad i psykologi, økonomi og økonomisk
          psykologi.
          <br />
          <br />
          Akademisk erfaring fra universiteter og høyskoler. Praktisk
          erfaring fra virksomheter og offentlige institusjoner.
          <br />
          <br />
          Praktiske akademikere med løsninger som fungerer i virkeligheten.
          "
        />
      );
    }
    return (
      <Interweave
        content="
          Institute for Climate Psychology. The Climate Psychologists. Climate Psychology Norway.
          <br />
          <br />
          Climate friendly choices. Sustainable decisions. Change for positive climate impact. People, firms and institutions.
          <br />
          <br />
          Three psychologists with PhDs in psychology, economy and economic psychology.
          <br />
          <br />
          Academic experience from Universities and Business Schools. Real life experience from business corporations and public institutions.
          <br />
          <br />
          Practical academics with solutions that work in practice.
        "
      />
    );
  };

  return (
    <Section>
      <InnerContainer>
        <ContentWrapper>
          <Content>
            <Title>{renderTitle()}</Title>
            <Separator />
            <Description>{renderDescription()}</Description>
            <Text>{renderText()}</Text>
          </Content>
        </ContentWrapper>
      </InnerContainer>
    </Section>
  );
};

export default Welcome;
