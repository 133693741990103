import { Interweave } from "interweave";
import { useSelector } from "react-redux";
import {
  Bold,
  Content,
  ContentWrapper,
  Description,
  InnerContainer,
  Section,
  Separator,
  Text,
  Title,
} from "./styled";

const NatureClimate = () => {
  const language = useSelector((state) => state.config.language);

  const renderTitle = () => {
    if (language === "no") {
      return "Natur og klima";
    }
    return "Nature and climate";
  };

  const renderDescription = () => {
    if (language === "no") {
      return (
        <Interweave
          content="
          Det er naturlig å være ute i naturen. Kontakt med natur skaper mental ro, intelektuell klarhet og emosjonell ro. Folk trenger natur for å trives.
          <br />
          <br />
          Den svekkede forbindelsen mellom mennesker og natur forsterker global oppvarming. Natur og klima er tett forbundet. Ødeleggelse av natur forstyrrer klimaet. Klimaforstyrrelser ødelegger naturen.
          <br />
          <br />
          Den psykologiske splitting mellom folk og natur virker ødeleggende. Folk og firmaer som ikke er forbundet innover til sin egen kjerne og utover til naturen rundt seg, varme opp klimaet og ødelegger jorden. Ubevisste beslutningstakere, grådighet og frykt skaper overforbruk og vekst som ødelegger verden.
          <br />
          <br />
          Folk, relasjoner og natur skaper meningsfulle liv. Ivaretakelse av naturen stabiliserer klimaet. Forbindelse til naturen stabiliserer folk.
          "
        />
      );
      //return "Det er naturlig å være ute i naturen. Kontakt med natur skaper mental ro, intellektuell klarhet og emosjonell ro. Folk trenger natur for å trives.";
    }
    return (
      <Interweave
        content="
        It’s natural to be in nature. Nature provides mental peace, intellectual clarity and emotional stability. People need nature to feel happy and satisfied.
        <br />
        <br />
        The disconnect between people and nature increases global warming. Climate and nature are connected. Destruction of nature causes climate disruptions. Climate disruptions cause destruction of nature.
        <br />
        <br />
        The psychological splitting of humans and earth is destructive. Disconnected people and organizations make decisions that undermine the climate and destroy nature. Mindless people, fear and greed leads to overconsumption and destruction.
        <br />
        <br />
        People, relationships and nature provide meaningful lives. Preservation of nature stabilizes the climate. Connection to nature stabilizes people.
        "
      />
    );
    //return "It’s natural to be in nature. Nature provides mental peace, intellectual clarity and emotional stability. People need nature to feel happy and satisfied.";
  };

  return (
    <Section>
      <InnerContainer>
        <ContentWrapper>
          <Content>
            <Title>{renderTitle()}</Title>
            <Separator />
            <Description>{renderDescription()}</Description>
          </Content>
        </ContentWrapper>
      </InnerContainer>
    </Section>
  );
};

export default NatureClimate;
