import { combineReducers } from "@reduxjs/toolkit";
import { config } from "./config";

const appReducer = combineReducers({
  config,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
