import styled from "@emotion/styled";

export const NavigationContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 3;
`;

export const InnerNavigationContainer = styled.div`
  padding: 20px 20px;
  text-align: center;
`;

export const Logo = styled.img`
  max-width: 250px;
  @media (max-width: 767px) {
    position: absolute;
    left: 10px;
    top: 15px;
    max-width: 220px;
  }
`;

export const SelectionContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const SelectButton = styled.button`
  border: none;
  background-color: transparent;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
`;
