import { Interweave } from "interweave";
import { useSelector } from "react-redux";
import {
  Bold,
  Content,
  ContentWrapper,
  Description,
  Fader,
  InnerContainer,
  Section,
  Separator,
  Text,
  Title,
} from "./styled";

const Challenges = () => {
  const language = useSelector((state) => state.config.language);

  const renderTitle = () => {
    if (language === "no") {
      return "Klimapsykologiske utfordringer";
    }
    return "Climate psychology challenges";
  };

  const renderDescription = () => {
    if (language === "no") {
      return "Klimakrisen er et resultat av økonomisk grådighet, psykologisk forsvar og enfoldige forestillinger. Frykt og reaktivitet hinder innovasjon og forandring.";
    }
    return "The climate crisis is a result of financial greed, defensive thinking and biased human decision processes. It's about how fear and reactiveness prevent innovation and change.";
  };

  const renderText = () => {
    if (language === "no") {
      return (
        <Interweave
          content="
          <Bold>Klimagrådighet: </Bold>Jeg har rett til det jeg har lyst på,
          og fortjener alt jeg kan få.<Bold> Klimakatastrofe: </Bold>Dette
          er et for stort problem til at vi kan gjøre noe med det.
          <Bold> Klimaforvirring: </Bold>Det er uklart hva som egentlig
          betyr noe og hva vi kan gjøre.<Bold> Klimadissonans: </Bold>Dette
          ser bare helt forferdelig ut, men jeg trenger å ha det fint.
          <Bold> Klimaavstand: </Bold>Konsekvensene kommer senere og vil
          ikke ramme meg. Andre kan betale hva det koster senere.
          <Bold> Klimaskam: </Bold>Andre bør føle seg skikkelig skyldige og
          må skamme seg.<Bold> Klimaangst: </Bold>Dette er bare for mye, jeg
          vil ikke snakke om det.<Bold> Klimaapati: </Bold>Det er uklart hva
          vi bør gjøre, så la oss bare vente. <Bold> Klimatvil: </Bold>
          Forskningen er for usikker, vi vet ikke nok til å gjøre noe.
          <Bold> Klimabenekting: </Bold>Dette skjer ikke. Det er ikke sant."
        />
      );
    }
    return (
      <Interweave
        content="
          <Bold>Climate greed: </Bold>I want what I want and deserve it all.
          <Bold> Climate catastrophe: </Bold>This is too terrible and too big to fix.
          <Bold> Climate confusion: </Bold>It is unclear what really matters and what we can do.
          <Bold> Climate dissonance: </Bold>This looks very, very bad, but I want to feel good.
          <Bold> Climate distance: </Bold>It won’t happen now, and it won’t happen to me. Someone else can pay later.
          <Bold> Climate shame: </Bold>Someone else are to blame – and others need to change.
          <Bold> Climate shut down: </Bold>This is just too much, I don’t want to talk about it.
          <Bold> Climate apathy: </Bold>It is unclear what we should do so let’s wait.
          <Bold> Climate doubt: </Bold>The science is to uncertain – there are other explanations.
          <Bold> Climate denial: </Bold>This isn’t happening. It’s not true."
      />
    );
  };

  return (
    <Section>
      <InnerContainer>
        <ContentWrapper>
          <Content>
            <Title>{renderTitle()}</Title>
            <Separator />
            <Description>{renderDescription()}</Description>
            <Text>{renderText()}</Text>
          </Content>
        </ContentWrapper>
      </InnerContainer>
    </Section>
  );
};

export default Challenges;
