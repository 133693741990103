import { Interweave } from "interweave";
import { useSelector } from "react-redux";
import {
  Bold,
  Content,
  ContentWrapper,
  Description,
  InnerContainer,
  Section,
  Separator,
  Text,
  Title,
} from "./styled";

const Projects = () => {
  const language = useSelector((state) => state.config.language);

  const renderTitle = () => {
    if (language === "no") {
      return "Prosjekter & kunder";
    }
    return "Projects & clients";
  };

  const renderDescription = () => {
    if (language === "no") {
      return "Vi holder foredrag, workshops og kurs. Vi gjør analyser og samler inn fakta som beslutningsgrunnlag. Vi gir konkret bistand til endring og iverksetting. Kontakt oss for mer informasjon om hva vi kan bidra med. Dette er noen av prosjektene våre knyttet til bærekraft og klimapsykologi:";
    }
    return "We provide talks, lectures, workshops and training. We research and analyze science, facts and best practices. We support change and implementation programs. Please contact us for more information about what we can do for you. These are some of our projects related to sustainability and climate psychology:";
  };

  const renderText = () => {
    if (language === "no") {
      return (
        <Interweave
          content="
          Hvordan tenke det utenkelige. Konsekvenser av klimaforandringer.
          <br />
          <br />
          Hvordan utforme og iverksette en bærekraftig og klimavennlig forretningsstrategi.
          <br />
          <br />
          Fra innsikt til handling. Vitenskapelig kunnskap om klimaavtrykk.
          <br />
          <br />
          Fra innsikt til forandring. Forskningsbasert innsikt om metoder for forandring.
          <br />
          <br />
          Klimavennlig påvirkningspsykologi. Hvordan kommunisere for å motivere til forandring.
          <br />
          <br />
          Hvordan utforme beslutningsarkitektur som påvirker i riktig retning.
          <br />
          <br />
          Hvordan styrke gjennomføringsevne gjennom opplæring av ledere og ansatte.
          <br />
          <br />
          Hvordan kommunisere for å bygge troverdighet og klimavennlig posisjon.
          "
        />
      );
    }
    return (
      <Interweave
        content="
        Thinking the unthinkable. Understanding consequences of climate change.
        <br />
        <br />
        How to design and implement a sustainable business strategy.
        <br />
        <br />
        Knowledge for action. Scientific insights about climate footprints.
        <br />
        <br />
        Knowledge for change. Scientific insights about methods for change.
        <br />
        <br />
        Design sustainable decision architectures that nudge the right choices.
        <br />
        <br />
        The psychology of persuasion. The behavioral science of climate communication.
        <br />
        <br />
        Build implementation capacity. Train leaders and professionals.
        <br />
        <br />
        Communicate success to build credibility and reputation.
    "
      />
    );
  };

  return (
    <Section>
      <InnerContainer>
        <ContentWrapper>
          <Content>
            <Title>{renderTitle()}</Title>
            <Separator />
            <Description>{renderDescription()}</Description>
            <Text>{renderText()}</Text>
          </Content>
        </ContentWrapper>
      </InnerContainer>
    </Section>
  );
};

export default Projects;
