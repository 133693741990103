import {
  Content,
  ContentWrapper,
  Description,
  EmployeeCol,
  EmployeeMail,
  EmployeeName,
  EmployeeRole,
  EmployeeRow,
  EmployeesWrapper,
  EmployeeThumbnail,
  InnerContainer,
  Section,
  Separator,
  Title,
} from "./styled";
import KnutIvarImage from "../../assets/img/profile-knut-ivar.jpg";
import PerEspenImage from "../../assets/img/profile-per-espen.jpg";
import RagnhildImage from "../../assets/img/profile-ragnhild.jpg";
import GryImage from "../../assets/img/profile-gry.jpg";
import { useSelector } from "react-redux";

const Contact = () => {
  const language = useSelector((state) => state.config.language);

  const renderTitle = () => {
    if (language === "no") {
      return "Kontakt";
    }
    return "Contact";
  };

  const renderDescription = () => {
    if (language === "no") {
      return "Har du et spørsmål og ønsker å komme i kontakt med oss? Nedenfor finner du kontaktinformasjon til alle som er involvert i Klimapsykologene.";
    }
    return "Do you have a question or want to get in touch with us? Below you can find contact information for each person involved in the Institute for Climate Psychology.";
  };

  return (
    <Section>
      <InnerContainer>
        <ContentWrapper>
          <Content>
            <Title>{renderTitle()}</Title>
            <Separator />
            <Description>{renderDescription()}</Description>
            <EmployeesWrapper>
              <EmployeeRow>
                <EmployeeCol>
                  <EmployeeThumbnail src={KnutIvarImage} />
                  <EmployeeName>Knut Ivar Karevold</EmployeeName>
                  <EmployeeRole>Partner, leder</EmployeeRole>
                  <EmployeeMail href="mailto:knut.ivar@klimapsykologene.no">
                    knut.ivar@klimapsykologene.no
                  </EmployeeMail>
                </EmployeeCol>
                <EmployeeCol>
                  <EmployeeThumbnail src={PerEspenImage} />
                  <EmployeeName>Per Espen Stoknes</EmployeeName>
                  <EmployeeRole>Partner</EmployeeRole>
                  <EmployeeMail href="mailto:per.espen@klimapsykologene.no">
                    per.espen@klimapsykologene.no
                  </EmployeeMail>
                </EmployeeCol>
                <EmployeeCol>
                  <EmployeeThumbnail src={RagnhildImage} />
                  <EmployeeName>Ragnhild Nilsen</EmployeeName>
                  <EmployeeRole>Partner</EmployeeRole>
                  <EmployeeMail href="mailto:ragnhild@klimapsykologene.no">
                    ragnhild@klimapsykologene.no
                  </EmployeeMail>
                </EmployeeCol>
                <EmployeeCol>
                  <EmployeeThumbnail src={GryImage} />
                  <EmployeeName>Gry Ståsett</EmployeeName>
                  <EmployeeRole>Partner</EmployeeRole>
                  <EmployeeMail href="mailto:gry@klimapsykologene.no">
                    gry@klimapsykologene.no
                  </EmployeeMail>
                </EmployeeCol>
              </EmployeeRow>
            </EmployeesWrapper>
          </Content>
        </ContentWrapper>
      </InnerContainer>
    </Section>
  );
};

export default Contact;
