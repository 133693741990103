import { useSelector } from "react-redux";
import {
  ContactItem,
  ContactLink,
  ContactWrapper,
  Content,
  ContentWrapper,
  Description,
  InnerContainer,
  Section,
  Separator,
  Slide,
  SlideContent,
  Slider,
  Title,
} from "./styled";
import PhoneIcon from "../../assets/img/mobile.svg";
import EmailIcon from "../../assets/img/email.svg";
import LinkIcon from "../../assets/img/link.svg";

const About = () => {
  const language = useSelector((state) => state.config.language);

  const renderTitle = () => {
    if (language === "no") {
      return "Om oss";
    }
    return "About us";
  };

  const renderDescription = () => {
    if (language === "no") {
      return "Tre psykologer med doktorgrad i psykologi, økonomi og økonomisk psykologi. En leder, veileder og kunstner med doktorgrad i innovasjon og entrepenørskap. Akademisk erfaring fra universiteter og høyskoler. Praktisk erfaring fra virksomheter og offentlige institusjoner. Bruk pilene på siden til å lære mer om hver enkelt av oss.";
    }
    return "Three psychologists with PhDs in psychology, economy and economic psychology. One leader, coach and artist with PhD in innovation and entrepreneurship. Academic experience from Universities and Business Schools. Real life experience from business corporations and public institutions.";
  };

  const renderPerEspenDescription = () => {
    if (language === "no") {
      return "Psykolog. PhD i økonomi. Førsteamanuensis ved Handelshøyskolen BI. Leder for Senter for grønn vekst ved Handelshøyskolen BI. Global TED foredragsholder. Sosialentrepenør. Med-grunder i GasPlas. Forfatter av fem bøker - inclusive Hva vi tenker på når vi prøver å ikke tenke på global oppvarming. Vararepresentant for MDG på Stortinget.";
    }
    return "Psychologist. PhD in Economics. Associate Professor at BI Norwegian Business School. Chair Center for Green Growth at BI Norwegian Business School. Global TED Speaker, Serial entrepreneur. Co-founder of clean-tech company GasPlas. Author of 5 books – including What We Think About When We Try Not To Think About Global Warming. Member of Norwegian Parliament.";
  };

  const renderKnutIvarDescription = () => {
    if (language === "no") {
      return "Organisasjonspsykolog. PhD i økonomisk psykologi og atferdsøkonomi. Fulbright gjesteforsker ved Harvard Business School (2013-2014). Førsteamanuensis og kursansvarlig ved Handelshøyskolen BI og universitetet i Oslo (2002-2018). Tidligere direktør og partner i GreeNudge.";
    }
    return "Organizational Psychologist. PhD in Behavioral Economics. Fulbright Scholar at Harvard Business School (2013-2014). Associate Professor and Senior Lecturer at BI Norwegian Business School and at the University of Oslo (2002-2018). Previous Partner and Director of GreeNudge.";
  };

  const renderGryDescription = () => {
    if (language === "no") {
      return "Klinisk psykolog. PhD i eksistensiell og psykodynamisk psykoterapi. Førsteamanuensis MF Vitenskapelig Høyskole. Spesialpsykolog Modum Bad.";
    }
    return "Clinical Psychologist. PhD in existentialistic and psychodynamic therapy. Associate Professor at Norwegian School of Theology. Senior Lecturer at Modum Bad.";
  };

  const renderRagnhildDescription = () => {
    if (language === "no") {
      return "Entrepenør, leder, veileder og kunstner. PhD i innovasjon og entrepenørskap. Med-grunder av Heliopolis Universitet for bærekraft i Kairo. Forfatter av 13 bøker. Komponist og artist med 5 utgivelser som merkevaren Arctic Queen.";
    }
    return "Entrepeneur, leader, coach and artist. PhD in innovation and entrepreneurship. Co-founder of Heliopolis University for sustainability in Cairo. Author of 13 books. Composer and artist with 5 productions under the brand Arctic Queen.";
  };

  return (
    <Section>
      <InnerContainer>
        <ContentWrapper>
          <Content>
            <Slider>
              <Slide>
                <SlideContent>
                  <Title>{renderTitle()}</Title>
                  <Separator />
                  <Description>{renderDescription()}</Description>
                </SlideContent>
              </Slide>
              <Slide>
                <SlideContent>
                  <Title>Dr. Per Espen Stoknes</Title>
                  <Separator />
                  <Description>{renderPerEspenDescription()}</Description>
                  <ContactWrapper>
                    <ContactLink href="tel:+4791595161">
                      <ContactItem src={PhoneIcon} />
                    </ContactLink>
                    <ContactLink href="mailto:per.espen@klimapsykologene.no">
                      <ContactItem src={EmailIcon} />
                    </ContactLink>
                    <ContactLink href="https://stoknes.no/">
                      <ContactItem src={LinkIcon} />
                    </ContactLink>
                  </ContactWrapper>
                </SlideContent>
              </Slide>
              <Slide>
                <SlideContent>
                  <Title>Dr. Knut Ivar Karevold</Title>
                  <Separator />
                  <Description>{renderKnutIvarDescription()}</Description>
                  <ContactWrapper>
                    <ContactLink href="tel:+4791707075">
                      <ContactItem src={PhoneIcon} />
                    </ContactLink>
                    <ContactLink href="mailto:knut.ivar@klimapsykologene.no">
                      <ContactItem src={EmailIcon} />
                    </ContactLink>
                    <ContactLink href="https://karevold.no/">
                      <ContactItem src={LinkIcon} />
                    </ContactLink>
                    <ContactLink href="https://psykopizza.no/">
                      <ContactItem src={LinkIcon} />
                    </ContactLink>
                  </ContactWrapper>
                </SlideContent>
              </Slide>
              <Slide>
                <SlideContent>
                  <Title>Dr. Gry Stålsett</Title>
                  <Separator />
                  <Description>{renderGryDescription()}</Description>
                  <ContactWrapper>
                    <ContactLink href="tel:+4791129387">
                      <ContactItem src={PhoneIcon} />
                    </ContactLink>
                    <ContactLink href="mailto:gry@klimapsykologene.no">
                      <ContactItem src={EmailIcon} />
                    </ContactLink>
                    <ContactLink href="https://www.mf.no/ansatte/gry-stalsett">
                      <ContactItem src={LinkIcon} />
                    </ContactLink>
                  </ContactWrapper>
                </SlideContent>
              </Slide>
              <Slide>
                <SlideContent>
                  <Title>Dr. Ragnhild Nilsen</Title>
                  <Separator />
                  <Description>{renderRagnhildDescription()}</Description>
                  <ContactWrapper>
                    <ContactLink href="tel:+4791884236">
                      <ContactItem src={PhoneIcon} />
                    </ContactLink>
                    <ContactLink href="mailto:ragnhild@klimapsykologene.no">
                      <ContactItem src={EmailIcon} />
                    </ContactLink>
                    <ContactLink href="http://www.globalfairtrade.no/">
                      <ContactItem src={LinkIcon} />
                    </ContactLink>
                  </ContactWrapper>
                </SlideContent>
              </Slide>
            </Slider>
          </Content>
        </ContentWrapper>
      </InnerContainer>
    </Section>
  );
};

export default About;
