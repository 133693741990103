import Navigation from "../components/navigation";
import Welcome from "./welcome";
import Challenges from "./challenges";
import Solutions from "./solutions";
import Projects from "./projects";
import NatureClimate from "./nature-climate";
import Contact from "./contact";
import About from "./about";

const Index = () => {
  return (
    <>
      <Navigation />
      <Welcome />
      <Contact />
      <Challenges />
      <Solutions />
      <About />
      <Projects />
      <NatureClimate />
    </>
  );
};

export default Index;
